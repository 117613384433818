<template>
  <v-dialog
    v-model="isOpen"
    max-width="400"
  >
    <v-card>
      <v-card-title class="subheading">
        Send Alert
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-container>
            <v-layout row>
                <v-flex xs12>
                  <v-select
                    v-bind="sendFields.tenant"
                    :items="allTenants"
                    v-model="selectedTenant"
                    @change="tenantChange"
                    item-text="name"
                    item-value="code"
                  />
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex xs12>
                  <v-autocomplete
                    :items="allUsers"
                    v-model="selectedUser"
                    v-bind="sendFields.userId"
                    item-text="userId"
                    item-value="id"
                    return-object
                  />
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex xs12>
                  <v-select
                    v-bind="sendFields.application"
                    :items="tenantApps"
                    @change="appChange"
                    v-model="selectedApp"
                    item-text="name"
                    item-value="code"
                  />
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex xs12>
                  <v-select
                    v-bind="sendFields.template"
                    :items="nonEnrollTemplates"
                    v-model="messageModel.template"
                    item-text="name"
                    item-value="name"
                    @change="setTemplateData"
                    :disabled="selectedApp === null"
                  />
                </v-flex>
            </v-layout>
            <v-layout row v-if="messageModel.metadata.type === 'native'">
              <v-flex xs12>
                <BaseSelectChip
                  label="Selected Biometrics"
                  :value="tenantTemplateBiometrics"
                  :available="transformedTenantBiometrics"
                  itemValue="code"
                  itemText="code"
                  :disabled=true
                />
              </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex xs12>
                  <v-text-field v-bind="sendFields.bodyText"
                    v-model="messageModel.metadata.bodyText" />
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex xs12>
                  <v-text-field
                    v-bind="sendFields.maxResponseAttempts"
                    v-model="messageModel.maxResponseAttempts"
                  />
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex xs12>
                  <v-text-field v-bind="sendFields.postbackUrl"
                    v-model="messageModel.postbackUrl"
                  />
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex xs12>
                  <v-text-field
                    v-bind="sendFields.expiresIn"
                    v-model="messageModel.expiresIn"
                  />
                </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <BaseButton @clicked="closeClick()" color="accent" text="Cancel" />
        <BaseButton @clicked="saveClick()" color="accent" text="Send" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { sendFields } from '@/components/config/userAlerts';
import tenantService from '@/services/TenantService';
import appTemplateService from '@/services/ApplicationTemplateService';
import tenantUserService from '@/services/TenantUserService';
import Message from '@/models/Message';
import TenantUser from '@/models/TenantUser';
import tenantTemplateService from '@/services/TenantTemplateService';

export default {
  name: 'AlertSendDialog',
  props: {
    user: Object,
  },
  data: () => ({
    sendFields,
    isOpen: false,
    selectedUser: new TenantUser({}),
    selectedApp: null,
    selectedTenant: null,
    messageModel: new Message({}),
    originalBodyText: '',
  }),
  computed: {
    ...mapGetters('app', ['getStickyTenant', 'getTopRole']),
    ...mapGetters('tenantApplications', {
      tenantApps: 'getAll',
    }),
    ...mapGetters('applicationTemplates', {
      appTemplates: 'getAll',
    }),
    ...mapGetters('tenants', {
      allTenants: 'getAll',
    }),
    ...mapGetters('alertMessageDialog', {
      allUsers: 'getAll',
      userbyKey: 'getByKey',
      getSendMessageModal: 'getSendMessageModal',
      getIsGlobal: 'getIsGlobal',
      getLastTemplate: 'getLastTemplate',
    }),
    ...mapGetters('tenantTemplateBiometrics', {
      tenantTemplateBiometrics: 'getAll',
      transformedTenantBiometrics: 'transformedTenantBiometrics',
    }),
    nonEnrollTemplates() {
      // We need to remove the ENROLL template from the list of available templates.
      let nonEnrollTemplates = [];
      if (this.appTemplates && this.appTemplates.length > 0) {
        nonEnrollTemplates = this.appTemplates.filter(template => template.name !== 'ENROLL');
      }

      return nonEnrollTemplates;
    },
  },
  components: {
    BaseButton: () => import('@/components/base/BaseButton'),
    ColorPickerButton: () => import('@/components/dialogs/ColorPickerButton.vue'),
    BaseSelectChip: () => import('@/components/base/BaseSelectChip'),
  },
  methods: {
    ...mapActions('tenantApplications', [
      'fetchTenantApps',
    ]),
    ...mapActions('applicationTemplates', {
      fetchAppTemplates: 'fetchAllBy',
    }),
    ...mapActions('tenants', {
      fetchTenants: 'fetchRoleTenants',
    }),
    ...mapActions('alertMessageDialog', {
      fetchTenantUsers: 'fetchAllBy',
      setLastTemplate: 'setLastTemplate',
      clearLastTemplate: 'clearLastTemplate',
    }),
    ...mapActions('userAlerts', {
      sendAlertMessage: 'sendMessage',
    }),
    ...mapActions('alertMessageDialog', ['toggleSendMessageModal']),
    ...mapActions('tenantTemplateBiometrics', {
      fetchTenantTemplateBiometrics: 'fetchTenantTemplateBiometrics',
      clearIncludedBiometrics: 'clearAll',
    }),
    async appChange(appCode) {
      if (appCode) {
        this.selectedApp = appCode;
        // Set the template code from state if it is set and it is
        // in the list of available templates for this app.
        const vm = this;
        const matchingTemplates = this.appTemplates.filter(
          template => template.name === vm.getLastTemplate,
        );
        if (matchingTemplates.length > 0) {
          this.messageModel.template = this.getLastTemplate;
        }
        // We have to call setTemplateData(), since we're setting it manually
        // and a lot of things get set in that function.
        this.setTemplateData();

        await this.fetchAppTemplates({
          service: appTemplateService,
          key: 'name',
          fetchBy: {
            appCode,
            tenantCode: this.selectedTenant,
          },
        });
      }
    },
    async saveClick() {
      if (this.$refs.form.validate()) {
        // We have to build the message object, because we only want to send values that
        // are overridden in this form. The values to look at are:
        //  bodyText
        //  expiresIn
        //  maxResponseAttempts
        //  postbackUrl
        const alertMessage = {};
        const overrideFields = ['bodyText', 'expiresIn', 'maxResponseAttempts', 'postbackUrl'];
        overrideFields.forEach((field) => {
          // We have to do an extra check for bodyText because the field is filled with
          // the default value from the template definition.
          if (field === 'bodyText') {
            if (this.messageModel.metadata.bodyText !== this.originalBodyText) {
              alertMessage.metadata = {};
              alertMessage.metadata.bodyText = this.messageModel.metadata.bodyText;
              alertMessage.metadata.reason = this.messageModel.metadata.bodyText;
            }
          } else if (this.messageModel[field].length > 0) {
            alertMessage[field] = this.messageModel[field];
          }
        });
        await this.sendAlertMessage({
          person: this.selectedUser,
          message: alertMessage,
          tenantCode: this.selectedTenant,
          appCode: this.selectedApp,
          template: this.messageModel.template,
        });
        // Hide the modal.
        this.toggleSendMessageModal(this.getIsGlobal);
        // Clear out the included biometrics from state, since it is
        // template dependant.
        this.clearIncludedBiometrics();
        // Set the last template ID so it can be used for the next time.
        this.setLastTemplate(this.messageModel.template);
      }
    },
    async fetchApps(tenantCode) {
      if (tenantCode) {
        await this.fetchTenantApps({ service: tenantService, key: 'code', tenantCode });
      }
    },
    async fetchUsers(tenantCode) {
      if (tenantCode) {
        await this.fetchTenantUsers({ service: tenantUserService, key: 'id', fetchBy: { tenantCode } });
      }
    },
    tenantChange(code) {
      this.selectedTenant = code;
      this.populateModal(code);
      // Clear out the lastTemplateId from state.
      this.clearLastTemplate();
    },
    closeClick() {
      this.toggleSendMessageModal(false);
      this.clearIncludedBiometrics();
      this.showCustom = false;
    },
    clearModal() {
      this.messageModel = new Message({});
      this.selectedUser = new TenantUser({});
      this.selectedApp = null;
    },
    async populateModal(code) {
      this.clearModal();

      await this.fetchTenants({ service: tenantService, key: 'code' });
      if (code) {
        this.selectedTenant = code;
      } else {
        this.selectedTenant = this.getStickyTenant;
      }

      // Get the tenant data so we can get the postbackUrl.
      const tenantData = this.allTenants.filter(tenant => tenant.code === this.selectedTenant);
      this.messageModel.postbackUrl = tenantData[0].defaultPostBack;

      if (!this.getIsGlobal) {
        this.selectedUser = this.user;
      }
      await this.fetchApps(this.selectedTenant);
      await this.fetchUsers(this.selectedTenant);
    },
    toggleDynamic(value) {
      this.showCustom = value === 'native';
    },
    getUpdatedColor(colorValue, fieldName) {
      this.messageModel.metadata[fieldName] = colorValue;
    },
    async setTemplateData() {
      const vm = this;
      if (this.messageModel.template.length > 0) {
        const filterTemplate = this.appTemplates.filter(
          appTemplate => appTemplate.name === vm.messageModel.template,
        );
        // Use array destructuring to get value from first (and only) item returned.
        const [currTemplate] = filterTemplate;
        this.messageModel = new Message(currTemplate);
        this.messageModel.template = currTemplate.name;
        // Stash the bodyText value from the template to use for comparison when
        // the alert is actually sent.
        this.originalBodyText = currTemplate.bodyText;
      }
      await this.clearIncludedBiometrics();
      // Reset the list of included biometrics for the selected template.
      await this.fetchTenantTemplateBiometrics({
        service: tenantTemplateService,
        key: 'algCode',
        tenantCode: this.getStickyTenant,
        appCode: this.selectedApp,
        templateName: this.messageModel.template,
      });
    },
  },
  watch: {
    getSendMessageModal() {
      this.isOpen = !this.isOpen;
    },
    async isOpen(open) {
      if (open) {
        await this.populateModal();
      }
    },
  },
};
</script>
