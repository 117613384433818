class Message {
  constructor(message) {
    this.expiresIn = '';
    this.maxResponseAttempts = '';
    this.metadata = {};
    this.metadata.type = message.type || '';
    this.metadata.bodyText = message.bodyText || '';
    this.postbackUrl = message.postbackUrl || '';
    this.template = message.template || '';
  }
}

export default Message;
